'use client'
import type { FC, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import StyledComponentsRegistry from '@/utils/styled-components-registry'
import { ThemeProvider } from 'styled-components'
import { theme } from '@/styles'
import { TranslationsProvider, ToastProvider, getTranslations, ModalsProvider, SessionProvider, FiltersProvider } from '@/context'

const DynamicModalMenu = dynamic(() => import('@/components/modals/ModalMenu').then(mod => mod.ModalMenu), { ssr: false })

type Props = {
  children: ReactNode
}

const ClientProviders:FC<Props> = ({ children }) => {
  const dictionary = getTranslations('en')

  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <TranslationsProvider localization={dictionary}>
          <ToastProvider>
            <SessionProvider>
              <FiltersProvider>
                <ModalsProvider>
                  {children}
                  <DynamicModalMenu />
                </ModalsProvider>
              </FiltersProvider>
            </SessionProvider>
          </ToastProvider>
        </TranslationsProvider>
      </ThemeProvider>
    </StyledComponentsRegistry>
  )
}

export default ClientProviders
